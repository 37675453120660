body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropdown-menu {
  z-index: 100000 !important;
}

.leaflet-container {
  height: calc(100vh - 64px);
  width: 100%;
  margin: 0 auto;
}

.leaflet-control-zoom {
  background: black !important;
}

.leaflet-control-zoom-in  {
  background: black !important;
  color: white !important;
}

.leaflet-control-zoom-out {
  background: black !important;
  color: white !important;
}

nav {
  background-color: rgba(0,0,0,1) !important;
}

