
.map-sidebar {
  position: absolute;
  background-color: black;
  height: calc(100vh - 64px);
  top: 64px;
  right: 0px;
  z-index: 10000;
}

.map-sidebar-open {
  width: 30vw;
}

.map-sidebar-closed {
  width: 15px;
}

.map-sidebar-body {
  padding: 0px 15px;
}

.map-sidebar-toggle {
  cursor: pointer;
  display: flex;
  border-radius: 15px 0px 0px 15px;
  width: 30px;
  height: 45px;
  position: absolute;
  top: 50vh;
  z-index: 10000;
  background-color: black;
  color: white;
}

.toggle-arrow {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.map-sidebar-toggle-open {
  right: 30vw;
}

.map-sidebar-toggle-closed {
  right: 15px;
}

.sidebar-input {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

